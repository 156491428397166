import { Trans, useTranslation } from 'react-i18next'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  const { t } = useTranslation()

  return (
    <BaseModal title={t('About')} isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        <Trans i18nKey="aboutModalText">
          This is an open source clone of the game Wordle -{' '}
          <a href="https://github.com/hannahcode/wordle" className="underline font-bold">
            check out the code here
          </a>{' '}
          and{' '}
          <a href="https://www.powerlanguage.co.uk/wordle/" className="underline font-bold">
            play the original here
          </a>
        </Trans>
      </p>
    </BaseModal>
  )
}
