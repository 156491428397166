import { WORDS } from '../constants/wordlist'
import { VALIDGUESSES } from '../constants/validGuesses'

export const isWordInWordList = (word: string) => {
  return WORDS.includes(word.toLowerCase()) || VALIDGUESSES.includes(word.toLowerCase())
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getWordOfDay = () => {
  // January 1, 2022 Game Epoch //1641013200000;
  const epochMs = new Date(Date.UTC(2022, 0, 1)).getTime()

  const now = Date.now()
  const msInDay = 1000 * 60 * 60 * 0.5
  const index = Math.floor((now - epochMs) / msInDay) % WORDS.length
  const nextday = (index + 1) * msInDay + epochMs

  return {
    solution: WORDS[index % WORDS.length].toUpperCase(),
    solutionIndex: index,
    tomorrow: nextday,
  }
}

export const { solution, solutionIndex, tomorrow } = getWordOfDay()
