import { useTranslation } from 'react-i18next'
import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  const { t } = useTranslation()

  return (
    <BaseModal title={t('How to play')} isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">{t('howToPlay1')}</p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="А" status="correct" />
        <Cell value="В" />
        <Cell value="Р" />
        <Cell value="А" />
        <Cell value="Л" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">{t('howToPlay2')}</p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="П" />
        <Cell value="И" />
        <Cell value="Л" status="present" />
        <Cell value="О" />
        <Cell value="Т" />
      </div>
      <p className="text-sm text-gray-500">{t('howToPlay3')}</p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="З" />
        <Cell value="А" />
        <Cell value="З" />
        <Cell value="О" status="absent" />
        <Cell value="Р" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">{t('howToPlay4')}</p>
      <p className="text-sm text-gray-500 dark:text-gray-300">{t('howToPlay5')}</p>
    </BaseModal>
  )
}
