export const VALIDGUESSES = [
  'абака',
  'абрек',
  'абрис',
  'абшид',
  'авгит',
  'авгур',
  'автол',
  'агама',
  'агами',
  'аграф',
  'адряс',
  'ажгон',
  'айван',
  'аймак',
  'айсор',
  'акажу',
  'акант',
  'аксон',
  'актёр',
  'аларм',
  'аллюр',
  'алтей',
  'алчба',
  'алыча',
  'амбра',
  'амвон',
  'амёба',
  'анион',
  'анкер',
  'антик',
  'антре',
  'анчар',
  'аншеф',
  'апорт',
  'апрош',
  'аргон',
  'аргус',
  'арека',
  'арест',
  'ариец',
  'аркан',
  'армяк',
  'архар',
  'аскер',
  'аспид',
  'астат',
  'асцит',
  'аттик',
  'аэрон',
  'бабка',
  'бабьё',
  'бадан',
  'байга',
  'байда',
  'байка',
  'бакан',
  'бакен',
  'бакун',
  'балок',
  'балык',
  'банка',
  'бареж',
  'барий',
  'барин',
  'барит',
  'барич',
  'барка',
  'бармы',
  'баска',
  'басма',
  'баснь',
  'басня',
  'басок',
  'басон',
  'батог',
  'бахта',
  'бачок',
  'безик',
  'бейка',
  'бекар',
  'бекас',
  'белец',
  'бельё',
  'беляк',
  'белёк',
  'бивак',
  'билль',
  'бирюч',
  'битьё',
  'битюг',
  'блато',
  'блинт',
  'бобок',
  'бобёр',
  'бодяк',
  'божба',
  'божок',
  'бокал',
  'бонза',
  'бонмо',
  'бонна',
  'борей',
  'борок',
  'борть',
  'ботик',
  'бочаг',
  'бочар',
  'бочок',
  'брада',
  'брика',
  'бриль',
  'бритт',
  'броня',
  'брыжи',
  'брыла',
  'бубна',
  'бубон',
  'будра',
  'будяк',
  'букан',
  'буква',
  'букле',
  'букля',
  'букса',
  'булга',
  'булла',
  'бурав',
  'бурат',
  'бурса',
  'бурун',
  'былка',
  'быльё',
  'бытьё',
  'бычок',
  'бювар',
  'бювет',
  'бюкса',
  'бёрдо',
  'вабик',
  'валах',
  'валец',
  'валка',
  'валок',
  'валун',
  'валух',
  'валёк',
  'валёр',
  'ванта',
  'варка',
  'варок',
  'ватин',
  'ватка',
  'ведун',
  'вежда',
  'векша',
  'венок',
  'веред',
  'верес',
  'верея',
  'верки',
  'верша',
  'весок',
  'ветла',
  'вешка',
  'вещун',
  'взбег',
  'взвар',
  'взлёт',
  'взмёт',
  'взрез',
  'видок',
  'вилок',
  'винцо',
  'вирша',
  'виски',
  'вития',
  'витьё',
  'вихор',
  'вишня',
  'власы',
  'внука',
  'вогул',
  'возик',
  'возка',
  'возок',
  'ворог',
  'ворьё',
  'вотум',
  'вотяк',
  'вошка',
  'вояка',
  'враки',
  'враль',
  'вруша',
  'втора',
  'выбой',
  'вывал',
  'вывес',
  'вывоз',
  'выгар',
  'выгон',
  'выжиг',
  'вытьё',
  'вышка',
  'вязок',
  'вятич',
  'вёдро',
  'гавот',
  'гагат',
  'газик',
  'газок',
  'галун',
  'ганза',
  'ганка',
  'гарда',
  'гарус',
  'гемма',
  'герма',
  'гетра',
  'гиляк',
  'гинея',
  'гипюр',
  'гирло',
  'гитан',
  'гитов',
  'гичка',
  'гнейс',
  'гнома',
  'гобой',
  'годик',
  'годок',
  'голец',
  'голик',
  'голыш',
  'гольд',
  'гольё',
  'горно',
  'горюн',
  'гофре',
  'граве',
  'гранд',
  'графа',
  'гребь',
  'грена',
  'гридь',
  'грозд',
  'грёза',
  'гуано',
  'губан',
  'гугня',
  'гужик',
  'гузно',
  'гумма',
  'гумми',
  'гумно',
  'гумус',
  'гуран',
  'гурда',
  'гурия',
  'гусак',
  'гусит',
  'гусёк',
  'гуцул',
  'гюрза',
  'дадан',
  'дачка',
  'дебит',
  'дебри',
  'девон',
  'дедка',
  'дедок',
  'деизм',
  'деист',
  'делёж',
  'демос',
  'денёк',
  'дерма',
  'дерть',
  'десть',
  'детва',
  'детка',
  'джига',
  'дзета',
  'диана',
  'дикая',
  'динго',
  'дичок',
  'добор',
  'дойна',
  'дойра',
  'домер',
  'домёк',
  'донец',
  'донка',
  'донна',
  'донце',
  'донья',
  'дорка',
  'досев',
  'досол',
  'дофин',
  'дошка',
  'драга',
  'дрема',
  'дрена',
  'дрога',
  'друза',
  'дрёма',
  'дубец',
  'дубка',
  'дубок',
  'дубьё',
  'дувал',
  'дудак',
  'дунец',
  'дунит',
  'дунст',
  'дурра',
  'дурро',
  'дутар',
  'дутик',
  'дутыш',
  'дутьё',
  'духан',
  'душка',
  'душок',
  'дщерь',
  'дымок',
  'дырка',
  'дюбек',
  'дюкер',
  'дёжка',
  'дёрен',
  'ежиха',
  'ездка',
  'елина',
  'емеля',
  'ерник',
  'ехида',
  'жакан',
  'жакоб',
  'жамка',
  'жарок',
  'жевок',
  'желна',
  'желть',
  'жеода',
  'жерех',
  'живьё',
  'жильё',
  'жирок',
  'житьё',
  'жменя',
  'жнива',
  'жниво',
  'жулан',
  'жульё',
  'жупан',
  'жупел',
  'журка',
  'жучка',
  'жучок',
  'жёлоб',
  'жёлчь',
  'жёнка',
  'загиб',
  'задел',
  'задик',
  'задок',
  'заеда',
  'зажин',
  'зажор',
  'зазыв',
  'зайка',
  'закол',
  'закуп',
  'закут',
  'зализ',
  'залёт',
  'замер',
  'замор',
  'замёт',
  'запев',
  'запон',
  'зарез',
  'зарод',
  'зарок',
  'засев',
  'засол',
  'засыл',
  'заток',
  'затёк',
  'затёс',
  'заумь',
  'зачин',
  'зачёс',
  'зачёт',
  'защип',
  'звень',
  'зельц',
  'земец',
  'зенки',
  'зернь',
  'зипун',
  'злато',
  'злюка',
  'знать',
  'зобик',
  'золка',
  'зраза',
  'зубец',
  'зубик',
  'зубок',
  'зулус',
  'зурна',
  'зыбка',
  'зыбун',
  'зятик',
  'зятёк',
  'иваси',
  'ивина',
  'ивняк',
  'игиль',
  'игрек',
  'игрун',
  'идиом',
  'ижица',
  'избач',
  'извет',
  'извив',
  'извод',
  'излёт',
  'измол',
  'измор',
  'изюбр',
  'илька',
  'ильма',
  'инвар',
  'индий',
  'инока',
  'инула',
  'иония',
  'иприт',
  'ирмос',
  'искус',
  'испод',
  'иссоп',
  'истод',
  'ишиас',
  'кабил',
  'кабул',
  'кавун',
  'кагал',
  'каган',
  'кагат',
  'кадий',
  'кайен',
  'кайла',
  'кайло',
  'кайма',
  'кайра',
  'калан',
  'калиф',
  'калла',
  'камас',
  'камея',
  'камка',
  'камса',
  'камус',
  'камча',
  'канюк',
  'капер',
  'капок',
  'капор',
  'капот',
  'каппа',
  'карга',
  'карда',
  'карел',
  'кариб',
  'карст',
  'катар',
  'катыш',
  'кацап',
  'кашка',
  'кварк',
  'кегля',
  'кекур',
  'кельт',
  'келья',
  'кенар',
  'кенаф',
  'кивер',
  'кизил',
  'кизяк',
  'киник',
  'кирза',
  'кирка',
  'кирха',
  'кисет',
  'кисея',
  'кифоз',
  'кичка',
  'кладь',
  'клака',
  'клика',
  'клико',
  'клипс',
  'клуня',
  'клупп',
  'клюка',
  'клёкт',
  'клёст',
  'кнель',
  'кникс',
  'кница',
  'кобза',
  'кобыз',
  'ковач',
  'ковёр',
  'кожан',
  'кожьё',
  'козон',
  'козёл',
  'койне',
  'кокет',
  'кокор',
  'колер',
  'колет',
  'колик',
  'колит',
  'колка',
  'колли',
  'колоб',
  'колок',
  'колон',
  'колун',
  'колча',
  'комми',
  'коник',
  'конка',
  'конёк',
  'копал',
  'копач',
  'копир',
  'копка',
  'копна',
  'копра',
  'копун',
  'копыл',
  'копьё',
  'копёр',
  'корда',
  'корча',
  'корчь',
  'корьё',
  'коряк',
  'косач',
  'косая',
  'косец',
  'космы',
  'косок',
  'косьё',
  'котик',
  'котёл',
  'кофей',
  'кофий',
  'кочет',
  'кошма',
  'кощей',
  'крага',
  'крапп',
  'кредо',
  'креол',
  'крепь',
  'кресс',
  'криль',
  'крица',
  'кроат',
  'кроки',
  'крона',
  'кроше',
  'крупа',
  'круть',
  'круча',
  'кручь',
  'крыса',
  'кубло',
  'кудлы',
  'кудри',
  'кузня',
  'кукиш',
  'кукша',
  'кулан',
  'кулеш',
  'кулёк',
  'кумач',
  'кумжа',
  'кумык',
  'кунак',
  'купаж',
  'курай',
  'курец',
  'курия',
  'курка',
  'курья',
  'кутас',
  'куток',
  'кутум',
  'кутья',
  'кутёж',
  'кухва',
  'кухта',
  'кучка',
  'кушак',
  'кхмер',
  'кюрий',
  'кюрин',
  'лабаз',
  'лабет',
  'лавра',
  'лагун',
  'лазея',
  'лазка',
  'лазок',
  'лайба',
  'лайда',
  'лакей',
  'ламут',
  'ландо',
  'ланды',
  'ланка',
  'лапка',
  'ларго',
  'ларёк',
  'латка',
  'латук',
  'латыш',
  'лафет',
  'лафит',
  'лачка',
  'лбина',
  'лбище',
  'левит',
  'легат',
  'легаш',
  'ледок',
  'лемеш',
  'лемма',
  'ленок',
  'ленто',
  'ленца',
  'лерка',
  'лесок',
  'леток',
  'лешак',
  'лещик',
  'ливан',
  'лизин',
  'лизис',
  'лизол',
  'ликёр',
  'лилея',
  'лиман',
  'линёк',
  'липец',
  'липка',
  'липси',
  'лирик',
  'лиска',
  'литва',
  'лития',
  'литка',
  'литьё',
  'лихва',
  'лишек',
  'лобан',
  'лобик',
  'логик',
  'ложок',
  'локон',
  'ломик',
  'ломка',
  'лонжа',
  'лохмы',
  'лоция',
  'лошак',
  'лубок',
  'лужок',
  'лузга',
  'лунит',
  'лунка',
  'лупка',
  'лучик',
  'лучок',
  'лысун',
  'лытка',
  'лычко',
  'льяло',
  'любка',
  'люнет',
  'люпин',
  'люпус',
  'люрик',
  'лютня',
  'люффа',
  'лючок',
  'лягва',
  'лярва',
  'ляссе',
  'ляшка',
  'лёжка',
  'лётка',
  'магик',
  'маета',
  'мазар',
  'мазер',
  'мазик',
  'мазка',
  'мазло',
  'мазур',
  'мазут',
  'майер',
  'майна',
  'макао',
  'макса',
  'макси',
  'малик',
  'малка',
  'малый',
  'малыш',
  'мальё',
  'малёк',
  'маман',
  'мамбо',
  'мамка',
  'манер',
  'мание',
  'манко',
  'манна',
  'манок',
  'манси',
  'манто',
  'маран',
  'марго',
  'марля',
  'матюг',
  'матюк',
  'махан',
  'махра',
  'медок',
  'медяк',
  'мезга',
  'мезон',
  'мелок',
  'мелос',
  'менка',
  'мерин',
  'мерка',
  'месса',
  'метол',
  'метро',
  'меццо',
  'мигач',
  'мидия',
  'микст',
  'милка',
  'милок',
  'мильт',
  'мимик',
  'минёр',
  'миома',
  'мираб',
  'мирза',
  'мирок',
  'мирон',
  'мирра',
  'мирта',
  'митоз',
  'митра',
  'мишка',
  'мишук',
  'млеко',
  'могар',
  'могол',
  'модий',
  'модус',
  'мойра',
  'мокко',
  'мокой',
  'молвь',
  'молка',
  'молох',
  'молян',
  'моляр',
  'морцо',
  'мосье',
  'мотет',
  'мотка',
  'мотто',
  'мохна',
  'мохны',
  'мошна',
  'мразь',
  'муаре',
  'музга',
  'музей',
  'мумиё',
  'мураш',
  'мурза',
  'мурин',
  'мурья',
  'мутон',
  'муфти',
  'муцин',
  'мучка',
  'мушар',
  'мушка',
  'мысик',
  'мытьё',
  'мышей',
  'мышка',
  'мюзет',
  'мюрид',
  'мялка',
  'мянда',
  'мясцо',
  'мятьё',
  'мётка',
  'набоб',
  'навал',
  'навет',
  'навис',
  'навой',
  'нагиб',
  'нагон',
  'нагул',
  'надир',
  'надув',
  'нажиг',
  'нажин',
  'назём',
  'накол',
  'накра',
  'налеп',
  'налим',
  'налой',
  'налёт',
  'намин',
  'намол',
  'намыв',
  'намёк',
  'намёт',
  'нанду',
  'нанка',
  'нанос',
  'напев',
  'напой',
  'нарта',
  'нарыв',
  'насад',
  'насып',
  'натяг',
  'натёк',
  'натёс',
  'нафта',
  'наход',
  'начин',
  'начёс',
  'начёт',
  'наяда',
  'невер',
  'негус',
  'недра',
  'немая',
  'немка',
  'ненка',
  'нерка',
  'нетяг',
  'нечет',
  'нивка',
  'низка',
  'низок',
  'никто',
  'нилот',
  'ничто',
  'ничья',
  'нищая',
  'новик',
  'новое',
  'ножка',
  'нойон',
  'нолик',
  'номад',
  'нонет',
  'нория',
  'норов',
  'носач',
  'носик',
  'нотис',
  'нотка',
  'ночка',
  'нудга',
  'нукер',
  'нулик',
  'нутро',
  'нырец',
  'нытьё',
  'обвоз',
  'обдел',
  'обдир',
  'обжин',
  'обком',
  'облов',
  'облог',
  'облом',
  'облёт',
  'обмер',
  'обмин',
  'обмол',
  'обмыв',
  'обмёт',
  'ободь',
  'обрат',
  'обруб',
  'обсев',
  'обчёт',
  'общее',
  'объём',
  'овсец',
  'овсюг',
  'овчар',
  'одурь',
  'одёжа',
  'ожина',
  'озимь',
  'ойрот',
  'окиян',
  'окоём',
  'окрол',
  'октет',
  'олеин',
  'олеум',
  'олифа',
  'омега',
  'омуль',
  'онагр',
  'оникс',
  'онуча',
  'опала',
  'опара',
  'оплыв',
  'опоек',
  'опока',
  'опора',
  'оптик',
  'орава',
  'орала',
  'орало',
  'орарь',
  'оржад',
  'оркан',
  'орлец',
  'орлик',
  'орляк',
  'оршад',
  'ослик',
  'осляк',
  'осмий',
  'осмол',
  'особа',
  'осоед',
  'осока',
  'остит',
  'остюк',
  'остяк',
  'осётр',
  'отава',
  'отбив',
  'отвоз',
  'отгиб',
  'отгон',
  'отдух',
  'отзол',
  'откол',
  'откус',
  'отлог',
  'отлуп',
  'отлёт',
  'отмол',
  'относ',
  'отпал',
  'отрез',
  'отрог',
  'отруб',
  'отсев',
  'отцеп',
  'отчал',
  'отчёт',
  'отъём',
  'офеня',
  'офорт',
  'офсет',
  'ошеек',
  'ошкуй',
  'падог',
  'падуб',
  'падун',
  'падёж',
  'пайва',
  'пайза',
  'палаш',
  'палех',
  'палея',
  'палия',
  'палуб',
  'пампа',
  'панаш',
  'панна',
  'панно',
  'панты',
  'панье',
  'панья',
  'панёр',
  'папах',
  'папеж',
  'параф',
  'парез',
  'пария',
  'парка',
  'парня',
  'парун',
  'парша',
  'пасма',
  'пасмо',
  'пасюк',
  'патан',
  'патер',
  'патлы',
  'патуа',
  'пахви',
  'пахвы',
  'пахит',
  'пахта',
  'паяло',
  'певун',
  'пегаш',
  'пекан',
  'пекло',
  'пенис',
  'пенни',
  'пенье',
  'пеньё',
  'пенёк',
  'перга',
  'перло',
  'перси',
  'перье',
  'перёд',
  'песня',
  'песок',
  'петит',
  'пешец',
  'пешня',
  'пещер',
  'пещур',
  'пивко',
  'пигус',
  'пижма',
  'пижон',
  'пиита',
  'пикан',
  'пикет',
  'пикон',
  'пикша',
  'пикёр',
  'пилав',
  'пилат',
  'пилка',
  'пилон',
  'пилот',
  'пиния',
  'пинна',
  'пинта',
  'пипка',
  'пирит',
  'пирке',
  'пирок',
  'пироп',
  'питок',
  'питьё',
  'пифос',
  'пищик',
  'плавь',
  'плата',
  'плаун',
  'плена',
  'плеск',
  'плица',
  'плохо',
  'плюха',
  'плёсо',
  'пнище',
  'побои',
  'повал',
  'повет',
  'повой',
  'погиб',
  'погон',
  'подий',
  'подог',
  'подой',
  'поедь',
  'пожня',
  'пожог',
  'позык',
  'позём',
  'позёр',
  'пойка',
  'пойма',
  'покат',
  'полба',
  'полка',
  'полой',
  'полок',
  'полом',
  'полон',
  'поляш',
  'полёт',
  'помин',
  'помои',
  'помёт',
  'понюх',
  'попас',
  'попик',
  'попка',
  'порей',
  'порто',
  'поруб',
  'посад',
  'посул',
  'поташ',
  'потир',
  'потяг',
  'потёк',
  'почёт',
  'праща',
  'прель',
  'приор',
  'причт',
  'приём',
  'пропс',
  'проух',
  'проём',
  'прусс',
  'прыск',
  'пряха',
  'псарь',
  'псина',
  'псица',
  'псише',
  'пудик',
  'пукля',
  'пунец',
  'пурин',
  'пурка',
  'путец',
  'путик',
  'пучка',
  'пушок',
  'пыжик',
  'пырей',
  'пьеза',
  'пясть',
  'пяток',
  'пёрка',
  'пёсик',
  'радар',
  'разик',
  'разок',
  'разор',
  'раина',
  'ракия',
  'ракша',
  'ранет',
  'ранка',
  'ратай',
  'ратин',
  'рафия',
  'рахит',
  'рацея',
  'рачок',
  'рдест',
  'ребаб',
  'ревун',
  'редан',
  'редут',
  'резец',
  'ремез',
  'ремиз',
  'ренет',
  'рений',
  'репер',
  'репка',
  'репьё',
  'ржавь',
  'ржица',
  'ржище',
  'ризки',
  'рикша',
  'ринит',
  'рипус',
  'риска',
  'ритор',
  'ришта',
  'ровик',
  'рогоз',
  'родий',
  'рожак',
  'розан',
  'ройба',
  'ройка',
  'рокер',
  'ромец',
  'ромок',
  'ромша',
  'рондо',
  'ронжа',
  'ропак',
  'ростр',
  'ротик',
  'роток',
  'ртище',
  'ругня',
  'рудяк',
  'ружьё',
  'руина',
  'румба',
  'рупия',
  'русин',
  'рутил',
  'рыбец',
  'рыжак',
  'рыжик',
  'рысца',
  'рытьё',
  'рябец',
  'рябик',
  'рябок',
  'рядно',
  'рядок',
  'ряска',
  'рясна',
  'саами',
  'сабан',
  'сабеи',
  'сабей',
  'сабза',
  'сабур',
  'саван',
  'сагиб',
  'садно',
  'садок',
  'сазан',
  'сайда',
  'саква',
  'сакля',
  'салма',
  'салол',
  'салоп',
  'саман',
  'самум',
  'сапка',
  'сапун',
  'сапёр',
  'саржа',
  'сарыч',
  'сатин',
  'сатир',
  'сбора',
  'свара',
  'свиль',
  'связа',
  'севба',
  'севок',
  'сеево',
  'сезам',
  'сезон',
  'сейид',
  'сейша',
  'секач',
  'селен',
  'селин',
  'семит',
  'сенцо',
  'сенцы',
  'серка',
  'серко',
  'серна',
  'серсо',
  'серум',
  'сеунч',
  'сечка',
  'сиаль',
  'сивая',
  'сивер',
  'сивка',
  'сивко',
  'сивуч',
  'сиена',
  'сижок',
  'сизяк',
  'силка',
  'силок',
  'силон',
  'силур',
  'сильф',
  'синап',
  'синец',
  'синяя',
  'сипай',
  'сирен',
  'сирин',
  'систр',
  'ситар',
  'ситец',
  'ситро',
  'ситце',
  'скало',
  'скань',
  'скарн',
  'скачь',
  'скена',
  'скепт',
  'скирд',
  'склеп',
  'склиз',
  'скоба',
  'скопа',
  'скора',
  'скорм',
  'скрап',
  'скреп',
  'скуди',
  'скудо',
  'слань',
  'слега',
  'слива',
  'слуда',
  'слука',
  'смазь',
  'смоль',
  'смуть',
  'снедь',
  'сныть',
  'совик',
  'совка',
  'согды',
  'согра',
  'сойка',
  'сойма',
  'сойот',
  'солея',
  'солка',
  'соляр',
  'сомик',
  'сомье',
  'сорго',
  'сорит',
  'сорок',
  'сором',
  'сорус',
  'сосец',
  'сотая',
  'сохач',
  'сошка',
  'спина',
  'спора',
  'спрут',
  'спурт',
  'станс',
  'стега',
  'стезя',
  'стека',
  'стена',
  'стень',
  'степс',
  'стило',
  'стоик',
  'столб',
  'страз',
  'строп',
  'струг',
  'стыдь',
  'стынь',
  'суаре',
  'сулея',
  'сулой',
  'супец',
  'супин',
  'суржа',
  'сурик',
  'сурна',
  'сурок',
  'сусак',
  'сусек',
  'сусло',
  'сутаж',
  'сучок',
  'сучьё',
  'сушка',
  'схима',
  'сынок',
  'сыпец',
  'сырть',
  'сырца',
  'сырьё',
  'сычуг',
  'сюита',
  'сябёр',
  'сяжок',
  'сёмга',
  'тавот',
  'тавро',
  'таган',
  'такыр',
  'талер',
  'талес',
  'талик',
  'тамга',
  'танин',
  'танок',
  'тапир',
  'тапка',
  'тапёр',
  'тарын',
  'татка',
  'тафта',
  'тафья',
  'тахта',
  'тацет',
  'ташка',
  'твист',
  'телик',
  'телок',
  'темка',
  'тенёк',
  'термы',
  'терно',
  'тесло',
  'теург',
  'тиара',
  'тигра',
  'тимол',
  'типец',
  'типик',
  'титло',
  'тифон',
  'тифус',
  'тлень',
  'тодес',
  'тойон',
  'токай',
  'толай',
  'толща',
  'томан',
  'томат',
  'томик',
  'тондо',
  'торий',
  'торит',
  'тохар',
  'тракт',
  'транш',
  'трапп',
  'трасс',
  'треба',
  'треть',
  'треух',
  'трефа',
  'триас',
  'триба',
  'триер',
  'триод',
  'тромп',
  'тропа',
  'туаль',
  'тугай',
  'тугун',
  'тукан',
  'тулес',
  'тулий',
  'тулуз',
  'тулук',
  'тулун',
  'тулья',
  'тумак',
  'тупей',
  'тупец',
  'турач',
  'турок',
  'тутор',
  'тутти',
  'тучка',
  'тушка',
  'тщета',
  'тынок',
  'тырло',
  'тырса',
  'тюник',
  'тюрбе',
  'тюрбо',
  'тюрик',
  'тютюн',
  'тючок',
  'тябло',
  'тягло',
  'тёзка',
  'тёлка',
  'тёрка',
  'тёска',
  'тётка',
  'тёшка',
  'уазик',
  'уброд',
  'убрус',
  'увраж',
  'угода',
  'угрев',
  'удерж',
  'удило',
  'удэге',
  'удэхе',
  'узбек',
  'узина',
  'уклея',
  'укрюк',
  'улита',
  'умбра',
  'умище',
  'умора',
  'униат',
  'уника',
  'унион',
  'упрёк',
  'ураза',
  'урема',
  'урина',
  'урман',
  'усина',
  'усище',
  'ускок',
  'устав',
  'утица',
  'ухарь',
  'ухожь',
  'учёба',
  'ушица',
  'ушкан',
  'ушкуй',
  'ушник',
  'фабра',
  'фавор',
  'фавус',
  'фагот',
  'фадом',
  'фазис',
  'фалда',
  'фальц',
  'фарад',
  'фатум',
  'фатюй',
  'фация',
  'фаянс',
  'фелон',
  'фенол',
  'феска',
  'фетюк',
  'фиакр',
  'фижмы',
  'физия',
  'филей',
  'филин',
  'фильц',
  'филёр',
  'финка',
  'финна',
  'фиорд',
  'фитин',
  'фланк',
  'флейт',
  'флейц',
  'флешь',
  'флинт',
  'флокс',
  'флюид',
  'фолио',
  'фомка',
  'форте',
  'фоска',
  'фотон',
  'фофан',
  'франк',
  'фрахт',
  'фузея',
  'фукус',
  'фуляр',
  'фураж',
  'фурия',
  'фурма',
  'футер',
  'футор',
  'фуэте',
  'хабар',
  'хаджи',
  'хадис',
  'хайло',
  'хакас',
  'халда',
  'халиф',
  'хамит',
  'хамка',
  'хамса',
  'хамьё',
  'ханша',
  'хапун',
  'хатка',
  'хашиш',
  'хвала',
  'хедер',
  'хедив',
  'херес',
  'херик',
  'хи-хи',
  'хиазм',
  'хинду',
  'хинин',
  'хитин',
  'хитон',
  'хлупь',
  'хлюст',
  'хлябь',
  'хлёст',
  'хмара',
  'хмарь',
  'хмель',
  'хмурь',
  'хобби',
  'ходун',
  'холка',
  'хорал',
  'хорея',
  'хорёк',
  'хохол',
  'хряпа',
  'хряск',
  'хряст',
  'хурал',
  'цадик',
  'цанга',
  'цапка',
  'цапун',
  'цапфа',
  'царёк',
  'цевка',
  'цевьё',
  'целое',
  'цехин',
  'цикля',
  'цитра',
  'цуцик',
  'цыбик',
  'цыпка',
  'чабан',
  'чабер',
  'чабёр',
  'чакан',
  'чалка',
  'часик',
  'часок',
  'чебак',
  'чекан',
  'чемер',
  'чепан',
  'чепец',
  'черва',
  'через',
  'черен',
  'черта',
  'черёд',
  'чечен',
  'чечет',
  'чибис',
  'чижик',
  'чилим',
  'чинар',
  'чинка',
  'чирей',
  'чирик',
  'чирок',
  'чирус',
  'чиряк',
  'читка',
  'чомга',
  'чтица',
  'чубик',
  'чубук',
  'чувал',
  'чуваш',
  'чувяк',
  'чужая',
  'чужое',
  'чурек',
  'чутьё',
  'чухна',
  'чёбот',
  'чёлка',
  'чёска',
  'чётка',
  'шабер',
  'шабли',
  'шабот',
  'шабур',
  'шабёр',
  'шавка',
  'шажок',
  'шалон',
  'шамот',
  'шанец',
  'шанкр',
  'шасла',
  'шассе',
  'шасси',
  'шатия',
  'шатёр',
  'шванк',
  'шверт',
  'шевро',
  'шейка',
  'шелеп',
  'шелом',
  'шемая',
  'шибер',
  'шиизм',
  'шимми',
  'шинок',
  'шипок',
  'шипун',
  'шитво',
  'шитик',
  'шитьё',
  'шихта',
  'шишак',
  'шкерт',
  'шлица',
  'шмоль',
  'шнека',
  'шнява',
  'шняка',
  'шорня',
  'шофёр',
  'шпора',
  'шпрот',
  'шпуля',
  'шпунт',
  'шпынь',
  'шримс',
  'штамб',
  'штейн',
  'штосс',
  'штрек',
  'штыка',
  'шубка',
  'шугай',
  'шуйца',
  'шумок',
  'шурпа',
  'шуряк',
  'шушун',
  'шёпот',
  'щепка',
  'щепьё',
  'щерба',
  'щипец',
  'щипка',
  'щипок',
  'щитик',
  'щупик',
  'щурка',
  'щучка',
  'щёкот',
  'щёлка',
  'щёлок',
  'щётка',
  'щёчка',
  'эвенк',
  'эгида',
  'эгрет',
  'эдикт',
  'экзот',
  'эллин',
  'эммер',
  'эолит',
  'эпика',
  'эпонж',
  'эспри',
  'эфиоп',
  'эшарп',
  'югрич',
  'югурт',
  'юдоль',
  'юзист',
  'юкола',
  'юница',
  'юнкор',
  'юрага',
  'юферс',
  'ягель',
  'яичко',
  'яишня',
  'ямина',
  'ямища',
  'ярица',
  'яруга',
  'ярыга',
  'яспис',
  'ястык',
  'ясырь',
  'ятовь',
  'ёрник',
  'ёршик'
]
