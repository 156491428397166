import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import { loadGameStateFromLocalStorage, saveGameStateToLocalStorage } from './lib/localStorage'
import { InformationCircleIcon, ChartBarIcon, SunIcon } from '@heroicons/react/outline'
import { useState, useEffect } from 'react'
import { Alert } from './components/alerts/Alert'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { AboutModal } from './components/modals/AboutModal'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import {
  GAME_TITLE,
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  ABOUT_GAME_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
} from './constants/strings'
import { isWordInWordList, isWinningWord, solution } from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import './App.css'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      ru: {
        translation: {
          Wordle: 'Игра Wordle',
          'About this game': 'Об игре',
          'Game copied to clipboard': 'Игра скопирована в буфер обмена',
          'You won!': 'Ты выиграл',
          Share: 'Поделиться',
          About: 'Об игре',
          aboutModalText:
            'Это клон игры Wordle - <2>исходный код игры</2> и <6>тут можно поиграть в оригинальную игру</6>',
          'How to play': 'Как играть?',
          howToPlay1: 'Отгадай слово за 6 попыток. После каждой попытки, цвет буквы покажет как близка отгадка.',
          howToPlay2: 'Буква А есть в загаданном слове и на этой позиции.',
          howToPlay3: 'Буква Л есть в загаданном слове, но на другой позиции.',
          howToPlay4: 'Буквы О нет в загаданном слове.',
          howToPlay5: 'Не забудь нажать ENTER, после того как напечатал слово.',
          Reset: 'Сбросить',
          ENTER: 'Проверить',
        },
      },
    },
    lng: 'ru', // if you're using a language detector, do not define the lng option
    fallbackLng: 'ru',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

const ALERT_TIME_MS = 2000

export const App = () => {
  const { t } = useTranslation()
  const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false)
  const [isNotEnoughLetters, setIsNotEnoughLetters] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isWordNotFoundAlertOpen, setIsWordNotFoundAlertOpen] = useState(false)
  const [isGameLost, setIsGameLost] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme') ? localStorage.getItem('theme') === 'dark' : prefersDarkMode ? true : false
  )
  const [successAlert, setSuccessAlert] = useState('')
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === 6 && !gameWasWon) {
      setIsGameLost(true)
    }
    return loaded.guesses
  })

  const [stats, setStats] = useState(() => loadStats())

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDarkMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  useEffect(() => {
    saveGameStateToLocalStorage({ guesses, solution })
  }, [guesses])

  useEffect(() => {
    if (isGameWon) {
      setSuccessAlert(WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)])
      setTimeout(() => {
        setSuccessAlert('')
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
      }, ALERT_TIME_MS)
    }
  }, [isGameWon, isGameLost])

  const onChar = (value: string) => {
    if (currentGuess.length < 5 && guesses.length < 6 && !isGameWon) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(currentGuess.slice(0, -1))
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }
    if (!(currentGuess.length === 5)) {
      setIsNotEnoughLetters(true)
      return setTimeout(() => {
        setIsNotEnoughLetters(false)
      }, ALERT_TIME_MS)
    }

    if (!isWordInWordList(currentGuess)) {
      setIsWordNotFoundAlertOpen(true)
      return setTimeout(() => {
        setIsWordNotFoundAlertOpen(false)
      }, ALERT_TIME_MS)
    }

    const winningWord = isWinningWord(currentGuess)

    if (currentGuess.length === 5 && guesses.length < 6 && !isGameWon) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))
        return setIsGameWon(true)
      }

      if (guesses.length === 5) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsGameLost(true)
      }
    }
  }

  const onReset = () => {
    setCurrentGuess('')
    setIsGameWon(false)
    setIsInfoModalOpen(false)
    setIsAboutModalOpen(false)
    setIsGameLost(false)
    setGuesses([])
  }

  return (
    <div className="py-8 max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div className="flex w-80 mx-auto items-center mb-8 mt-12">
        {/*{GAME_TITLE}*/}
        <h1 className="text-xl grow font-bold dark:text-white">{t('Wordle')}</h1>
        <SunIcon className="h-6 w-6 cursor-pointer dark:stroke-white" onClick={() => handleDarkMode(!isDarkMode)} />
        <InformationCircleIcon
          className="h-6 w-6 cursor-pointer dark:stroke-white"
          onClick={() => setIsInfoModalOpen(true)}
        />
        <ChartBarIcon className="h-6 w-6 cursor-pointer dark:stroke-white" onClick={() => setIsStatsModalOpen(true)} />
      </div>
      <Grid guesses={guesses} currentGuess={currentGuess} />
      <Keyboard onChar={onChar} onDelete={onDelete} onEnter={onEnter} guesses={guesses} />
      <InfoModal isOpen={isInfoModalOpen} handleClose={() => setIsInfoModalOpen(false)} />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        guesses={guesses}
        gameStats={stats}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShare={() => {
          setSuccessAlert(GAME_COPIED_MESSAGE)
          return setTimeout(() => setSuccessAlert(''), ALERT_TIME_MS)
        }}
      />
      <AboutModal isOpen={isAboutModalOpen} handleClose={() => setIsAboutModalOpen(false)} />

      {/*{ABOUT_GAME_MESSAGE}*/}
      <button
        type="button"
        className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 select-none"
        onClick={() => setIsAboutModalOpen(true)}
      >
        {t('About this game')}
      </button>
      <button
        type="button"
        className="mx-auto mt-8 flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={onReset}
      >
        {t('Reset')}
      </button>

      <Alert message={NOT_ENOUGH_LETTERS_MESSAGE} isOpen={isNotEnoughLetters} />
      <Alert message={WORD_NOT_FOUND_MESSAGE} isOpen={isWordNotFoundAlertOpen} />
      {/*<Alert message={`The word was ${solution}`} isOpen={isGameLost} />*/}
      <Alert message={`Ты прогирал, я загадал слово ${solution}`} isOpen={isGameLost} />
      {/*message={t('Game copied to clipboard')}*/}
      {/*<Alert message={CORRECT_WORD_MESSAGE(solution)} isOpen={isGameLost} />*/}
      <Alert message={successAlert} isOpen={successAlert !== ''} variant="success" />
    </div>
  )
}
